import React from 'react'
import Layout from '../../layout/Layout'
import Related from '../reusable/related';
import Coding from './coding'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';




class Assessment extends React.Component{
    componentDidMount() {
        document.title = "Felixrt - Current: Assessment"; 
     }
    
    
    render(){
        return (
            <React.Fragment>
                <Layout currentPage="Portfolio">
                    <div className="portfolio cyber">
                        <section className="section">
                            <div className="container-fluid">
                                
                        
                                
                                {/* Centred picture */}
                                <div className="row centred-image overview mb-5 cover">
                                    <div className="col-11 col-md-12 mx-auto text-center p-4 py-5 p-md-5">
                                            <img className="portfolio-img p-md-5 mx-md-4" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/ds1.png'} alt="Proserpexa cover" />
                                    </div>
                                </div>
                                <div className="row mt-5 pt-5 mb-0">
                                    <div className="col-md-10 col-11 mx-auto text-left">
                                    <h1 className="mb-1 px-3 px-md-0 font-tertiary">Felix's Defender Assignment</h1>
                                    </div>
                                </div>
                                {/* Main Description */}
                                <div className="row main-description">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                    <span className="tagline challenge mb-4">CHALLENGE</span>
                                        <p className="px-3 px-md-0">
                                        I did this assessment for Protexxa, a Cybersecurity business that helps other Enterprises secure their users through their SaaS application Defender. I enjoy helping purposes that make the world a better place to live for everyone, and security, in this case cybersecurity hits to the core of my values, so hope you enjoy the reading as I did creating it.
                                        </p>
                                    
                                      
                                    </div>
                                </div>
                                 {/* Triple Text */}
                                 <div className="row triple-text mt-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                                        <div className="row">
                                           
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">My Role</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>UX Designer</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Tools</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>Figma/Figjam</li>
                                                    <li>Chat-GPT (fake data)</li>
                                                    <li>Material Design</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Duration</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    1 day
                                                </p>
                                            </div>
                                          
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row main-description mb-5 pb-5">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                    <h3 className="text-left my-3">Context</h3>
                                        <p className='mb-3'>
                                             
<b>The goal is:</b> To create a general 8-hours design analysis and redesign in support of Protexxa and their main users, which are likely professionals in Cybersecurity/Management roles and their protectees, focusing on improving the experience of every human involved.
</p><p className='mb-3'>
<b>The problem to solve is:</b> to improve the Human Experience of using Defender balancing business and end users goals and making emphasis in aesthetics and usability so that Protexxa has a better positive impact in the lives of their cybersecurity manager clients and their protectees.

                                            
                                        </p>
                                        
                                        <p><b>Scope:</b> According to the challenge, deliverables must include:</p>
                                                        <ul className="mb-1">
                                                            <li>1-2 User personas</li> 
                                                            <li>Wireframes for product's key screens: Include rationale for layout choices.</li> 
                                                            <li>High-Fidelity Mockups from key screens: 1 screen (e.g. Home)</li> 
                                                        </ul>
                                    
                                      
                                    </div>
                                </div>
                                
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <h3 className="text-left my-3 mt-5">1. Research</h3>
                                        <p className='mb-2'>
                                        From visiting the website, and other channels I see marketing is targeting Cybersecurity professionals looking for services that solve the cyber hygiene problem from the human perspective, who will sub offer other services like training to other end users which are likely employees within the CISO's level of responsibilities, and general enterprise employees.    
                                        </p>
                                        <h5 className="mb-3 mt-4 text-left">User personas</h5>
                                        <img className="portfolio-img mt-1" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/pers1.png'} alt="Planning and methodolody" />
                                        <p className="portfolio-img mb-3 2 mt-1"><b>Keywords:</b> Manager, Compliance, Resources, Budget, Balance, Cyber Trends, Time</p>
                                        <img className="portfolio-img my-1" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/pers2.png'} alt="Planning and methodolody" />
                                        <p className="portfolio-img mb-3 mt-1"><b>Keywords:</b> Employee, Support, time, lack of cyber expertise, multiple responsibilities, balance</p>
                                        <p className='mb-2'>
                                        
                                        Both of these are our main users, Padmé because she's also the potential buyer persona and the main Administrator of Defender, and Leia because is the one who will defend(see what I did there?) the platform and pass the voice on its value. And I know the challenge only asks for two, but I'd like to give an honorable mention to our colleague from the sales team, <b>Luke</b>:

                                        </p>
                                        <img className="portfolio-img my-1" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/pers3.png'} alt="Planning and methodolody" />
                                        <p className="portfolio-img mb-3 mt-1"><b>Keywords:</b> Colleague, Trained, Balance, Accuracy, Demos, Trends, Time, Sales.
</p>
<p className='mb-2'>
                                        
We should also empathize with Luke (he/him), who works as a technical business representative supporting Protexxa. He should be able to use the platform effectively and without frustration when interacting with potential buyers, as they can easily discern when someone is struggling with the system.

</p>


                                        
                                        <h4 className="text-left ">Empathize</h4>
                                    
                                    <p>
                                    So let's feel these guys and see a pain point to tackle, that for the purposes of this challenge I chose “Time”, which is something Padmé, Leia and Luke have in common: their are are passionate about what they do, but maybe if the UX they get from Defender allows them to find, show and respond faster, maybe Padmé could have time to think about her family, Leia could use some to continue building up her career and Luke wouldn't burn out every meet and keep up the good spirit.

                                    </p>
                                    <h4 className="text-center my-4">Focus Pain Point</h4>
                                        <div className="row text-center">
                                            <div className="col-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Time</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                Padmé, Leia and Luke feel Defender could be more UX efficient, so they have more time.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row triple-text py-4 pb-5 mb-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h2 className="text-left my-3 mt-5">State of the art</h2>
            
                                
                                        
                                        
                                        <p>
                                        From the web we could get a current state of Defender (Images taken from website and youtube):

                                        </p>
                                        <img className="portfolio-img mt-2" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/current2.png'} alt="Planning and methodolody" />
                                        <img className="portfolio-img mb-2" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/current1.png'} alt="Planning and methodolody" />
                                        <p className='mt-3'>
                                            From this, I could spot four main pages: <b>Home</b> or dashboard, which can be for the entire company or for a single user, <b>Reverse Image AI</b>, <b>Incident Plan</b> and <b>Marketplace</b>. All of them good and thoughtful, but based on an heuristic analysis we could state the following insights:
                                        </p>
                                        <ul className="ml-0 mb-3">
                                            <li>Main dashboard visualizations contain readable information that merges in chunks of 9 (Indications of compromise, cyber hygiene merged), they are on the x-axis making it difficult to read.</li>
                                            <li>Not <b>clear call-to-action</b>: You can spot that issue more clearly at the Image AI Search, where there's no clear preferred button.</li>
                                            <li>The <b>score meaning</b>: from screens I could tell that 500ish is a 'good' score, but I had to really dig into more screens to realize that 1000 is good and 0 is bad. I see the word health, so I can see how you can differ here, but maybe there's small tricks that could help here.</li>
                                            <li><b>Visibility of status</b>: There is no way to recall where I am on the navigation, and how to quickly go to the key screens. Maybe those are the top icons, but that needs to be crystal clear .</li>
                                            <li><b>Iconography:</b> could help a lot to map concepts and metrics, I will add them.
</li>                                        </ul>
                                    </div>
                                </div>
                                <div className="row triple-text mb-5 pt-5 odd">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <div className="my-3 py-2">
                                            <h3 className="text-left my-3">A thought about scoring</h3>
                                            <p className='mb-3'>
                                            That scoring widget is taking a lot of space in some screens. Is it really that important? Here are ideas to consider: 
                                            </p>
                                            <ol className="mb-4">
                                                <li className="mb-2">Most of the widget is explaining the score in terms of the quantity and how it divides the health, and in some cases with a lot of text. We could say we are explaining the score in terms of percentages. </li>
                                                <li className="mb-2">Partners like Cisco and NordStellar are also using scores on their product, but they are using it to measure risk, which is the negative of ours, which measures health. Could we invert it, so we align with possible integrations with them?</li>
                                                <li className="mb-2">In any case, we could lose the explanations by introducing the score with the words HEALTH or RISK, and we could save cognitive time from our users.</li>
                                            </ol>
                                            <img className="portfolio-img mb-4" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/partners.png'} alt="Planning and methodolody" />
                                           
                                          
                                        </div>
                                    </div>
                                </div>

                    
                                <div className="row fourthe-text-important  my-4 pb-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="text-left mb-2 mt-5">2. Wireframes</h3>
                                                <p>
                                                    Based on our insights, let's propose updates on these key screens:
                                                </p>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-3">
                                            <div className="col-12 mx-auto">

                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <p className="mb-1 text-left">Company Dashboard</p>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-9 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/wf1.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        
                                                        <ul className="mb-1">
                                                            <li className="mb-2">We now have a <b>navigation on top</b> to give instant access to Padmé to get what she came to do today, this also helps Luke who is doing a demo next week, he will save a couple of clicks getting faster to the key features.</li>
                                                            <li className="mb-2">Visualizations that merges is now splitted: we'll have <b>two separate visualizations</b> and their text content will be aligned and to the left. People who read from left to right, up to down, tend to prefer this layout. The addition of the percentage on the x axis makes it understandable without a legend, so, less noise on the screen, and we don't need to explain it with legends.</li>
                                                            <li className="mb-2">“See all” categories implies actually that our data sources are not limited to those on screen.</li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                 {/* begin mscreen */}
                                                 <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                    <p className="mb-1 text-left">Company Dashboard</p>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-9 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/wf2.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        
                                                        <ul className="mb-1">
                                                            <li className="mb-2">Here we can see the simplifying of scoring, it allows us to add statistics so users can map what needs to be done more quickly.</li>                                                                              
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                                 
                                                
                                                {/* begin mscreen */}
                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                         <p className="mb-1 text-left">Company Dashboard</p>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-9 mb-2 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/wf3.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        
                                                        <ul className="mb-1">
                                                            <li className="mb-2">This screen changes the eye scanning method to scrolly-telling, this will make Leia's brain happier as well, since her eyes won't have to zig-zag through pairs.</li>
                                                            <li className="mb-2">Icons will be refined in high fidelity.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                     
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                             
                                <div className="row triple-text odd mt-4">
                                    
                                    <div className="col-11 col-md-10 mx-auto ">
                                        <div className="my-3 py-3 pb-4">
                                            <h3 className="text-left my-2 mt-5">3. High Fidelity</h3>
                                            <p>
                                               Here are two options with slight variations:
                                            </p>
                                            <p className="text-left my-1 mt-3 pt-3">Option 1</p>
                                            <img className="portfolio-img my-1" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/ds1.png'} alt="Planning and methodolody" />
                                            <p className="text-left my-1 mt-1 pt-2">Option 2</p>
                                            <img className="portfolio-img my-1" src={ process.env.PUBLIC_URL + '/images/challenge/proserpexa/ds2.png'} alt="Planning and methodolody" />
                                          
                                        </div>
                                        
                                    </div>
                                </div>
                              

                                
                                <div className="row centred-description-important pb-5 mb-4">
                                    <div className="col-11 col-md-10 mx-auto text-left">
                                    <h4 className="text-left my-3 mt-2 pt-5">Design rationales</h4>
                                        <ul className="ml-0 ">
                                            <li><b>Font family</b> is Geomanist, the same one you are already using, is a nice sans-serif, legible and good for reading on a computer, it's pretty, it's branded, it works, it's already there. Why change it?</li>
                                            <li><b>Colors</b> are the same you are using with the addition of wheel/complementary and opacity variations, again sourcing from your brand.</li>
                                            <li><b>Icons</b> were added to scan and navigate through the sites, so Padmé can quickly glance and find her metric, and Luke can suggest the attention on a presentation.</li>
                                            <li>The visualizations are now sorted for prioritization which Padmé will appreciate.</li>
                                            <li>Switching reading intention to vertical, aligned to left for readability/heuristic already explained.</li>
                                            <li>Color contrasts tests were taken, and we passed them 100%.</li>                                                                              
                                            <li>I'm always open to feedback, so I'm more than happy to iterate as needed, and even erase it and go back to the pen and paper.</li> 
                                        </ul>



                                  


                                    </div>
                                </div>
                                <div className="row triple-text mb-5 pt-4 odd">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <div className="my-3 py-2">
                                            <h3 className="text-left my-3">is it feasable?</h3>
                                            <p className='mb-3'>
                                            Did I mention I come from a Computer Science Engineering background? what that means in practice is that I like to take into consideration the technical implementations beforehand, and suggest <b>if</b> my developer friends like them. So here's a test written by chatGPT:
                                            </p>
                                           
                                            <Coding></Coding>

                                                

                                        <p className='mt-4'>
And that's all, Defender has a redesign MVP. Next steps: <b>Usability Testing!</b>




                                        </p>
<p>Want more? poke around or scroll for contact info.</p>
                                        <p className='mt-5'>-Felix</p>
                                           
                                          
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="mb-3">Related Projects</h4>
                                        <Related 
                                                
                                                title1="DESIGNING A CAD SOFTWARE"
                                                image1="/images/home/pentacad.png"
                                                type1="Portfolio" 
                                                url1="Pentacad"
                                                text1="CASE STUDY"

                                                title2="SALARY COMPARISON TOOL"
                                                image2="/images/challenge/xcorp/feature.png"
                                                type2="Challenge" 
                                                text2="CHALLENGE"
                                                url2="Xcorp"
                                                 
                                                 />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}
export default Assessment;