import React from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  brands, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'



class MenuBar extends React.Component {
    state = { time: moment().format('LTS') };
    
    componentDidMount() {
        setInterval(() => {
            moment.tz.setDefault("America/Santiago");
            this.setState({ time: moment().format('LTS') });
        }, 1000)
    }
    
    render(){
        return (
            <footer className="bg-dark footer-section odd">
                <div className="section pt-5 pb-5">
                    <div className="row">
                        <div className="col-md-10 mx-auto col-11">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <h5 className="font-secondary">Free, brave.</h5>
                                    <p className="lead-paragraph">Based in Latin America.</p>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <h5 className="font-secondary">My local time (GMT-3)</h5>
                                    <p className="lead-paragraph">{this.state.time}, {moment().format('ll')} </p>
                                </div>
                            </div>
                            <div className="row mb-5 pb-0">
                                <div className="col-12">
                                    <h5 className="font-secondary">Contact</h5>
                                    <p className="lead-paragraph mb-0">frivasto@gmail.com</p>
                                    <div className="mx-0">
                                        <a href="mailto:frivasto@gmail.com" rel="noreferrer" title="email" className='mr-1'>
                                            <FontAwesomeIcon icon={solid('envelope')}   />
                                        </a>   
                                        <a href="https://www.linkedin.com/in/frivasto" className='mx-1' title="LinkedIn" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={brands('linkedin')} size="s" />
                                        </a>
                                        <a href="https://twitter.com/frivasto" className='mx-1' title="Twitter" target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={brands('twitter')} size="s" />
                                        </a>
                                    </div>
                                    <p className="lead-paragraph mt-4">(Feel free) to reach out!</p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-top text-center border-dark py-5">
                    <p className="mb-0">No rights reserved. 
                        Inspire yourself as needed. Designed and coded with love by <strong>felixrt &lt;3</strong>
                    </p>
                </div>
            </footer>
        );
    }
}

export default MenuBar;