import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands, regular,solid } from '@fortawesome/fontawesome-svg-core/import.macro'

var Scroll   = require('react-scroll');
var scroller = Scroll.scroller;

class Header extends React.Component {
    scrollTo() {
        scroller.scrollTo('HomePortfolio', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -50
        })
      }
    render(){
        return (
            <React.Fragment>
                <section className="hero-area bg-primary position-relative">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-11 col-md-10 mx-auto text-left px-0">
                                <h2 className="cover-text font-secondary pb-2">
                                    Product Designer / UX Engineer
                                </h2>
                                <div className="row mt-5">
                                    <div className="col-12 col-md-6">
                                        <p className="cover-paragraph font-primary pl-1 pr-3">
                                        I'm a Designer with a solid technical & cross-curricular background. My goal is to build and re-build a sustainable, resilient world for all humans, while <span className="blurry-lines">I blur the lines between design and technology</span>;<br/>Oh!, and I also collaborate harmoniously with robots(AI).
                                        </p>
                                     
                                    
                                    </div>
                                </div>
                                <div className="row call-to-action mt-5">
                                    <div className="col-12 mx-auto text-left">
                                        <a to="HomePortfolio" className="dark btn btn-outline-secondary" onClick={() => this.scrollTo()}> <FontAwesomeIcon icon={solid('arrow-down')} size="xs" /> MY WORK</a>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                        
                    </div>

                    <ul className="list-unstyled mr-5 mt-5 position-absolute zindex-1 float-end networks">
                    <li className="mb-3">
                            <a href="https://docs.google.com/document/d/1PKfvPrzfo1oTHzFItXzfIiI9Q7Cln1Zh58TgAktwR_Y/edit?usp=sharing" title="Resumé" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={regular('file-lines')} size="xs" />
                            </a>
                        </li>
                        <li className="mb-3">
                            <a href="https://www.linkedin.com/in/frivasto" title="LinkedIn" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={brands('linkedin')} size="xs" />
                            </a>
                        </li>
                        
                        <li className="mb-3">
                            <a href="https://twitter.com/frivasto" title="Twitter" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={brands('twitter')} size="xs" />
                            </a>
                        </li>
                    </ul>
                </section>
            </React.Fragment>
        )
    }
}

export default Header;