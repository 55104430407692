import React from 'react'
import { Link } from "react-router-dom"

var Scroll   = require('react-scroll');
var Element  = Scroll.Element;



const HomePortfolio = () => {
    return (
        <React.Fragment>
            <Element name="HomePortfolio">
                <section className="section home-portfolio odd" id="portfolio">
                    <div className="container-fluid">
                        {/* Main Description */}
                        <div className="row main-description pb-3">
                            <div className="col-md-10 mx-md-auto col-12 text-left px-0">
                                <h2 className="mb-1 px-3 px-md-0">My work</h2>
                                <p className="px-3 px-md-0 pt-2 mb-2">
                                    Feel free to contact me for a walk-through:
                                </p>
                            </div>
                        </div>
                        {/* ping pong photo descriptions */}
                        <div className="row ping-pong-photo">
                            <div className="col-11 col-md-10 mx-auto">
                                 <div className="row align-items-center mb-5">
                                    <div className="col-12 col-md-6 px-0 pr-md-5">
                                        <Link to='/Current/Cyber'>
                                            <h2 className="mb-1 text-left">Managed Services Design for the Cybersecurity world</h2>
                                        </Link>
                                        <span className="mb-4 text-left tagline current"> ONGOING
                                        </span>

                                        <ul className="lead-paragraph mb-3 mb-md-0">
                                            <li>A glimpse of my current: Data Visualizations and Dashboards for VIPs within Businesses.</li>
                                            <li>Omni-channel Service Design for: web, email, slack and more.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Link to='/Current/Cyber'>
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/current/featured.png'} 
                                                    alt="Cybersecurity"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-5">
                                    <div className="col-12 col-md-6 px-0 pr-md-5">
                                        <Link to='/Portfolio/Thestralcorp'>
                                            <h2 className="mb-1 text-left">Dashboard SaaS UX/UI</h2>
                                        </Link>
                                        <span className="mb-4 text-left tagline case-study">CASE STUDY
                                        </span>

                                        <ul className="lead-paragraph mb-3 mb-md-0">
                                            <li>Collaborated in the research and visuals of several SaaS components.</li>
                                            <li>You can see my process and resources for Information Architecture, Wireframing/Prototyping, Design Systems, etc.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Link to='/Portfolio/Thestralcorp'>
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/home/thestralcorp.png'} 
                                                    alt="Thestral corp"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-5">
                                    <div className="col-12 col-md-6 px-0 pr-md-5">
                                        
                                        <Link to='/Portfolio/PentaCad'>
                                            <h2 className="mb-1 text-left">Creating a MS Software CAD Editor</h2>
                                        </Link>
                                        <span className="mb-4 text-left tagline case-study"> CASE STUDY
                                        </span>

                                        <ul className="lead-paragraph mb-3 mb-md-0">
                                            <li>Sprinting ahead and indroduce components and a brand new design system to an already running developing cycle.</li>
                                            <li>Includes: use of agile sprints methodology, thought process to create and test interactions and visuals for a Desktop Software.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 px-md-4 mb-3 mb-md-0">
                                        <Link to='/Portfolio/PentaCad'>
                                            <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/home/pentacad.png'} 
                                                    alt="PentaCad"/>
                                        </Link>
                                    </div>
                                </div>
                                 {/* See More */}
                                <div className="row call-to-action">
                                    <div className="col-12 mx-auto px-0 text-left">
                                        <Link to="/Portfolio" className="light btn btn-primary odd">MORE PROJECTS</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                                
                        
                    </div>
                </section>
            </Element>
        </React.Fragment>
    )
}

export default HomePortfolio;