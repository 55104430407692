import React from 'react'
import Layout from '../../layout/Layout'
import Related from '../reusable/related'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';



class Xcorp extends React.Component{
    componentDidMount() {
        document.title = "Felixrt - Challenge: Salary Comparison Tool"; 
     }
    
    
    render(){
        return (
            <React.Fragment>
                <Layout currentPage="Portfolio">
                    <div className="portfolio picnic">
                        <section className="section">
                            <div className="container-fluid">
                                
                                
                                
                                {/* Centred picture */}
                                <div className="row centred-image overview mb-5 cover">
                                    <div className="col-11 col-md-12 mx-auto text-center p-4 py-5 p-md-5">
                                            <img className="portfolio-img p-md-5 mx-md-4" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/comparison1.png'} alt="Thestralcorp cover" />
                                    </div>
                                </div>
                                <div className="row mt-5 pt-5 mb-0">
                                    <div className="col-md-10 col-11 mx-auto text-left">
                                    <h1 className="mb-1 px-3 px-md-0 font-tertiary">Salary comparison widget</h1>
                                    </div>
                                </div>
                                {/* Main Description */}
                                <div className="row main-description">
                                    <div className="col-md-8 offset-md-1 col-sm-12 text-left">
                                    <span className="tagline challenge mb-4">CHALLENGE</span>
                                        <p className="px-3 px-md-0">
                                            This widget helps users compare their current salary to <strong>median</strong> industry averages across 
                                            different locations for employees with similar characteristics such as skills, 
                                            education and years of experience.
                                        </p>
                                        <div className="row call-to-action mb-4">
                                            <div className="col-12 col-md-4  text-left">
                                                <a className='dark btn btn-outline-secondary' href="https://docs.google.com/document/d/1cerpXTe-XBj8bWEc5w5CcPlcngNKsGI5WeaWWz02EDs/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                <FontAwesomeIcon icon={solid("up-right-from-square")} /> <spam className="pl-2">Full Challenge Prompt</spam> 
                                                </a>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                                 {/* Triple Text */}
                                 <div className="row triple-text mb-5 pb-5 mt-3">
                                    <div className="col-11 col-md-10 mx-auto">
                                        
                                        <div className="row">
                                           
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">My Role</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>UX Designer</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Tools</h4>
                                                <ul className="pr-md-2 pr-sm-0">
                                                    <li>Figma</li>
                                                    <li>Miro</li>
                                                    <li>Material Design</li>
                                                    <li>Pencil and Paper</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <h4 className="mb-2">Date and (Duration)</h4>
                                                <p className="pr-md-2 pr-sm-0">
                                                    January 7, 2022 (3 days)
                                                </p>
                                            </div>
                                          
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <h3 className="text-left my-3 mt-5">Where do I start?</h3>
                                        <p className='mb-2'>
                                             I created this case study presentation in regards a UX/UI Design challenge for a beautiful company that from now on we are going to call 'XCorp'.
                                             After reading the assignment, I began my understanding of the challenge which stated that XCorp, 
                                             the challenger company, in an effort to pivot to a LinkedIn kind of business model, 
                                             wanted a salary comparison tool for their homepage.</p><p>
                                             The goal was to work on this challenge for no more than 20 hours to deliver a presentation and a 
                                             mid-fidelity design. In order to finish the within the time I decided to go for a double diamond design framework, 
                                             dividing my first two days to discover and define respectively, and the last day to develop and deliver the screens.
                                            
                                        </p>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/double-diamond.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>

                                <div className="row triple-text pt-4">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h2 className="text-left my-3 mt-5">Discover</h2>
            
                                
                                        
                                        <h3>Challenge Breakdown</h3>
                                        <p>
                                            First thing first, I started with a breakdown of the challenge to understand the problems and goals, 
                                            I used the whiteboard to do this and placed post-its for any idea that would come out.
                                        </p>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/001-challenge-breakdown.png'} alt="Planning and methodolody" />
                                        <h3 className='mt-4 mb-0'>Foundational research</h3>
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">

                                                <div className="row align-items-center mt-4">
                                                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/002-foundational-research.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-6">   
                                                        
                                                        <h5 className="mb-2 text-left">What is a median anyway?</h5>
                                                        <ul className="mb-1">
                                                            <li>According to wikipedia, In statistics and probability theory, the median is the value separating the higher half from the lower half of a data sample, a population, or a probability distribution.</li> 
                                                            <li>This means that an average salary median, for certain characteristics and different locations, would be the value in the middle from a dataset of all the salaries ordered from low to high. Giving a range from a 25% low and up a good probability of a salary for somebody with similar characteristics.</li> 
                                                            <li>Doesn't that sound like nice information to have for a professional employee?</li> 
                                                        </ul>
                                                    </div>
                                                </div>                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <div className="my-5 py-5">
                                            <h3 className="text-left my-3 mt-5">Based on this, I discovered XCorp's intentions</h3>
                                            <p className='mb-2'>
                                                X's <strong>goal</strong> is to develop a widget that lets users compare salary averages across locations which will 
                                                help them navigate their career journey by knowing how fair it is theirs. 
                                                This would also attract users to a pivoting XCorp which is a win win!. 
                                                The impact could be measured with user traffic through this channel, user retention, new users and returning users.
                                            </p>
                                            <h3 className='mt-5'>Assumptions and constraints</h3>
                                            <ul>
                                            <li>
                                                For purposes of this challenge, I will assume we already have a considerable database or service with quantitative information of 
                                                employees with salaries related to location, position, education, skills, years of experience and maybe not limited to that.
                                            </li>
                                            <li>
                                            I will also assume, based on the assessment, that all the characteristics remain constant, but the location would be variable so it can be the subject of comparison.
                                            </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row triple-text pt-4">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5">User research</h3>
                                       
                                        <h4>Who are the users anyway?</h4>
                                        <ul className="mb-3">
                                            <li>My hypothesis was to target people who used LinkedIn to actively look for jobs</li>
                                            <li>I created a plan to collect data with the few hours that I got</li>
                                            <li>I was able to do phone calls and mostly a small Google Forms survey</li>
                                            <li>I got enough information to create a "True" user persona</li>
                                           
                                        </ul>
                                        <div className="row call-to-action mb-4">
                                            <div className="col-12 col-md-2  text-left">
                                                <a className='dark btn btn-outline-secondary' href="https://docs.google.com/document/d/1nAgcvexb2JKcIGCSOxBigm7XVFZJR7FWy0ZOFoyoJaM/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    See Plan
                                                </a>
                                            </div>
                                            <div className="col-12 col-md-2  text-left">
                                                <a className='dark btn btn-outline-secondary' href="https://docs.google.com/spreadsheets/d/1YoejMgZJBbPFnVbaMyoXzkZci5vcbzpCl2wHIEjU8LM/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    See Data
                                                </a>
                                            </div>
                                            <div className="col-12 col-md-3 text-left">
                                                <a className='dark btn btn-outline-secondary' href="https://docs.google.com/forms/d/1LW8XNhe_cwCz_P2vEn6JSVwP2A5caTVrtSMuQBekagw/edit" target="_blank" rel="noreferrer">
                                                    See Google form
                                                </a>
                                            </div>
                                        </div>
                                      


                                        <img className="portfolio-img mb-3" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/003-user-research.png'} alt="Planning and methodolody" />
                                        
                                        <h3 className="text-left my-3 mt-2">Empathizing</h3>
                                        <p>
                                            With my new user persona “True”, I was able to put myself in her shoes everytime that I created an step of the experience focusing on a main pain point:
                                        </p>
                                    </div>
                                </div>

                                {/* Triple Text */}
                                <div className="row triple-text pb-5 mb-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Focus Pain Point</h4>
                                        <div className="row text-center">
                                            <div className="col-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Anxiety</h5>
                                                <p className="pr-md-2 pr-sm-0">
                                                    She gets anxious when asked for salary expectations during interviews. 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5">User Journey mapping</h3>
                                        <p>
                                            The main experience goal for True would be to change True's feelings of anxiety that 
                                            she is already having looking for jobs, to trigger feelings of security and excitement 
                                            for the salary comparison throughout the experience.
                                        </p>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/004-customer-journey-mapping.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>
                                <div className="row triple-text py-5">
                                    
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <div className="my-5 py-5">
                                            <h2 className="text-left my-2 mt-5">Define</h2>
                                            <h3 className="text-left my-3 mt-2">Problem Statement</h3>
                                            <p>
                                                True is a Professional who needs to compare her current salary to industry averages across locations because she wants to know if hers is fair.
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="text-left my-3 mt-5 pt-3">Competitive Audit</h3>
                                        <p>
                                            From the audit I found the following opportunities to be considered for my approach to the problem:
                                        </p>
                                        <ul className="ml-0">
                                            <li>Present information at first glance.</li>
                                            <li>Try two or more chart presentations per request.</li>
                                            <li>Present a single medium salary as main information, then the rest.</li>
                                            <li>Present information with autocomplete and a base result.</li>
                                            <li>Gather information to be comparable in chart.</li>
                                        </ul>
                                        <div className="col-12 col-md-4 text-left mt-4 mb-5 pb-4">
                                        <a className='dark btn btn-outline-secondary' href="https://docs.google.com/document/d/16XXshZHpIyuV5NXy25mYWNc2ykpSxEm-y8QchoTdm14/edit?usp=sharing" target="_blank" rel="noreferrer">
                                            See full audit
                                        </a>
                                    </div>
                                    </div>
                                </div>

                                <div className="row triple-text py-5">
                                    <div className="col-11 col-md-10 mx-auto mb-5 pb-4">
                                        <h3 className="text-left my-3 mt-5">User Stories</h3>
                                        <p>
                                            In order to split the functionalities in tasks in service of the user, here are the user needs I found:
                                        </p>
                                        <ul className="ml-0">
                                            <li>As a professional, I want to be able to see the median base salary for people similar to me highlighted, so I don't get anxious when asked in an interview.</li>
                                            <li>As a professional, I want a salary comparison tool so that I know how mine compares to industry averages across different locations.</li>
                                            <li>As a professional, I want to see my salary comparisons through charts so it's easier for me to visualize large amounts of complex data.</li>
                                            <li>As a professional, I want easy to understand input fields so I can write the following prompts: Education level, years of experience, skills, desired, location, job title, current salary and more.</li>
                                            <li>As a professional, I want to be able to see this widget on a smaller screen, so that I can access it from different devices.</li>
                                            <li>As a product owner, I want the result data visualizations to have relevant interactions like hover effects, and mouse clicks, so the experience is enhanced while remaining easy.</li>
                                            <li>As a product owner, I want a salary comparison widget at XCorp's homepage so users have a useful tool that complements their engagement to the company.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row triple-text py-5 cover">
                                    <div className="col-11 col-md-10 mx-auto mb-5">
                                        <div className="my-5 py-5">
                                            <h2 className='my-3 mt-5 text-center'>Develop</h2>
                                            <h3 className="text-center">“Good design is as little design as possible” -Dieter Rams</h3>
                                        </div>
                                    </div>
                                </div>

                               
                                 
                                <div className="row triple-text py-5">
                                    <div className="col-11 col-md-10 mx-auto">

                                        <h3 className="text-left my-3 mt-5">Concept Exploration</h3>
                                        <p>
                                        I started imagining the tool as a calculator. I wanted to follow Neilsen's heuristic principle of 
                                        matching the system with the real world, so the user has in hand a tool that feels familiar to it.
                                        </p>
                                        <img className="portfolio-img my-2" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/009-concept-exploration.png'} alt="Planning and methodolody" />
                                        <h3 className="mb-2 mt-4 text-left">User flow</h3>
                                        <p>
                                        Where a single salary comparison tool would be presented immediately, without any prompt, 
                                        so the user has to do not a single click to already get information, where the initial states would be very generic, 
                                        but the idea is to begin giving the user the feelings of security and already completing a task.
                                        </p>
                                        <img className="portfolio-img my-3" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/010-user-flow.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>

                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="mb-0 mt-3 text-left">Paper Wireframes</h3>
                                        <h4 className="mb-0 mt-4 text-left">A single input</h4>
                                        <img className="portfolio-img my-3 mb-5" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/011-paper-wireframe-1.png'} alt="Planning and methodolody" />
                                        <h4 className="mb-0 mt-4 text-left">A real life comparison tool</h4>
                                        <img className="portfolio-img my-3 mb-5" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/011-paper-wireframe-2.png'} alt="Planning and methodolody" />
                                        <h4 className="mb-0 mt-4 text-left">Also in small boxes</h4>
                                        <img className="portfolio-img my-3 mb-5" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/011-paper-wireframe-3.png'} alt="Planning and methodolody" />
                                        <h4 className="mb-0 mt-4 text-left">There is a solution for everything, always.</h4>
                                        <img className="portfolio-img my-3 mb-5" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/011-paper-wireframe-4.png'} alt="Planning and methodolody" />
                                       
                                    </div>
                                </div>
                                <div className="row triple-text pt-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h3 className="mb-0 mt-3 text-left">Whiteboard prototype test</h3>
                                        <p>Since I wasn't goint to be able to test with users, I translated these wireframes to the whiteboard, in order to test a main flow: editing and comparing salaries of two different locations:</p>
                                        <img className="portfolio-img my-3 mb-5" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/012-whiteboard-prototype-test.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>

                               
                                {/* Triple Text */}
                                <div className="row triple-text py-b mb-5 ">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="text-center my-4">Insights</h4>
                                        <div className="row text-center">
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                1
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Buttons? nah!</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    I realized 'plus' button for location comparison and 'calculate' buttons are not necessary, tool can recalculate directly after an edit.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                2
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Big display</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                Big result display is not necessary when comparing two locations.
                                                </p>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="row my-3">
                                                    <div className="col-12">
                                                        <div class="avatar avatar-sm">
                                                            <div class="avatar-title fs-lg bg-primary rounded-circle">
                                                                3
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="mb-4">Median Visualization</h5>
                                                <p className="pr-md-2 pr-sm-0 text-center">
                                                    Medians across the y axis have not comparative value.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-12">
                                                <h4 className="mb-0 mt-3 text-left">Adjusting the median visualization</h4>
                                                <p>For a better user understanding of the salary comparison, the median chart will be presented in 
                                                    a way that the X axis represents salary and median percentiles move across it.</p>
                                                <img className="portfolio-img my-3 mb-5" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/median-adjustments.png'} alt="Planning and methodolody" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
          
          
                                <div className="row triple-text py-5 odd">
                                    <div className="col-11 col-md-10 mx-auto">

                                        <h3 className="text-left my-2 mt-5">Styles and components: Google</h3>
                                        <ul>
                                            <li>For purposes of this challenge, and in order to have an MVP with the deliverables requested, I used material design resources to get similar styles to the ones X was already using on its website, as well as already built components.</li>
                                            <li>I will use Dark theme first to remain consistent with the current website.</li>
                                        </ul>
                                        <h3 className="text-left my-2 mt-5">Bootstrap, Dark Theme &amp; Google charts</h3>
                                        <ul>
                                            <li>A little birdie told me that we already have things built using Bootstrap, so unless we agree to experiment, we can take advantage of that and use their 12 column grid to layout our screens, for example.</li>
                                            <li>I based the visuals and interactions on the ones provided by Google charts, though are open to be discussed with 
                                                similar frameworks that contain candlestick, bubble, curve and Geo charts.</li>
                                        </ul>
                                        <img className="portfolio-img my-5 pb-4" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/013-design-and-components.png'} alt="Planning and methodolody" />
                                    </div>
                                </div>

                                <div className="row fourthe-text-important pt-4 mt-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="text-left mb-2 mt-5">Mid-Fidelity Screens</h3>
                                                <p>
                                                    After a few iterations, these are the final screens and considerations:
                                                </p>
                                            </div>
                                        </div>
                                        {/* ping pong photo descriptions */}
                                        <div className="row ping-pong-photo pt-0 mb-5">
                                            <div className="col-12 mx-auto">

                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">1. Start state</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-9 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/1-Start-state.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        
                                                        <p className="mb-1">
                                                            The initial state will present default prompts and already show a Median salary compared to a $0.00 current “my salary”.
                                                        </p>
                                                    </div>
                                                </div>

                                                 {/* begin mscreen */}
                                                 <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">2.a Big input autocomplete</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-9 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/2-big-input-autocomplete.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        
                                                        <ul className="mb-1">
                                                            <li>When the user starts typing, it will automatically detect possible autocomplete suggestions</li>
                                                            <li>It will also detect the prompt to edit automatically, whether it is a location, position, skill, experience, education or industry.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                                 
                                                
                                                {/* begin mscreen */}
                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">2.b Hover edit location</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-9 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/2-2-hover-edit-location.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        
                                                        <ul className="mb-1">
                                                            <li>Another way of adding a location for comparison is passing the mouse over the location prompt. It will show an edit button.</li>
                                                            <li>Users can delete a location comparison by clicking at the location's "X" at any time </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                                {/* begin mscreen */}
                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">3. Comparison</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-9 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/3-simplest-comparison.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        
                                                        <ul className="mb-1">
                                                            <li>After adding a new location, it will show a median comparison between the two locations, with the same characteristics.</li>
                                                            <li>The start state for "My salary" hasn't changed yet, it's still $0.00.</li>
                                                            <li>Big results display disappears.</li>
                                                        </ul>
                                                       
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                                {/* begin mscreen */}
                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">4. Editing through prompts</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-9 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/4-prompt-edit-location.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        
                                                        <p className="mb-1">
                                                            Prompt direct edit will also show autocomplete for: location, position, industry, experience and education.
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                                {/* begin mscreen */}
                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">5. Input error</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-9 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/5-input-error.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        
                                                        <p className="mb-1">
                                                            Inputs like "My salary" will display an error message if it doesn't pass validations.
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                                {/* begin mscreen */}
                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">6. Editing My Salary</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-12 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/7-input-correct.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                  
                                                </div>
                                                {/* end screen */}
                                                {/* begin mscreen */}
                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">7. Comparing against "My salary"</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-9 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/8-comparison.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        
                                                        <p className="mb-1">
                                                            One of the main user stories was to display at first glance a comparison against the user's current salary.
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                                {/* begin mscreen */}
                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">8. :hover chart</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-12 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/10-hover-2.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                                {/* begin mscreen */}
                                                <div className="row align-items-top mt-4">
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">9. Map view</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-12 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/11-map-view.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                                {/* begin mscreen */}
                                                <div className="row align-items-top mt-4">x
                                                    <div className="col-12">
                                                        <h4 className="mb-2 text-left">10. Responsive screens</h4>
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-12 col-md-7 mb-4 mb-md-0">
                                                        <img className="portfolio-img" src={ process.env.PUBLIC_URL + '/images/challenge/xcorp/12-responsive-screens.png'} 
                                                            alt="Digital Wireframe 1"/>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        
                                                        <p className="mb-1">
                                                            Mobile and smaller screens will present the same components distributed in two states: 
                                                        </p>
                                                        <ul className='my-2'>
                                                            <li>The main result and</li>
                                                            <li>The edit modal step.</li>
                                                        </ul>
                                                        <p>Though my main goal for this interface is that this edit modal step remains unused and eventually eliminated from the experience, and for that big input button to be the main controller.</p>
                                                    </div>
                                                </div>
                                                {/* end screen */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row centred-description-important pb-5">
                                    <div className="col-11 col-md-10 mx-auto text-left">
                                        <h3 className="mb-3 mt-5 text-left">What would I do differently/with more time?</h3>
                                        <ul className="mt-4">
                                            <li>Usability testing: This is a must</li>
                                            <li>Visual Design: High fidelity mockups, to put attention to the details</li>
                                            <li>Hover information: I wanted the salary base to be up front and users don't get distracted with more information, but I would have wanted to play with additional information when hovering the charts like: confidence of the numbers, additional payment, etc.</li>
                                            <li>I would definitely want to prototype and test the big input. my hypothesis is that it is enough and we do not need the hover edits.</li>
                                            <li>I would also want to introduce a change of variable, to give users the option to choose comparison per industry instead of location, big input could help with that too.</li>
                                            <li>Create the curve chart.</li>
                                            <li>Accessibility: Besides icons, I would have liked testing if the information is well readed by a text to speech, and color blindness contrast test. </li>
                                            <li>Dive a little deeper into the users, interviews would have been amazing, to really know their triggers and then try to shift their emotions to a more hopeful and motivational mood.</li>
                                            <li>Other Position suggestions, career path suggestions: Lead, Senior?</li>
                                       </ul>
                                        <h3 className="mb-3 mt-5 text-left">Thanks!</h3>
                                        <p className="mt-4">
                                            Hopefully tools like this would help True and people like her who struggle with money talk. 
                                            It felt good empathizing with her and trying to provide a tool that would make her life a little more fair, and making her connect to businesses who care for users like X.
                                            
                                        

                                            Thank you XCorp, for this challenge. It was fun to solve.
                                            
                                       </p>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-11 col-md-10 mx-auto">
                                        <h4 className="mb-3">Related Projects</h4>
                                        <Related 
                                                
                                                title1="DESIGNING A CAD SOFTWARE"
                                                image1="/images/home/pentacad.png"
                                                type1="Portfolio" 
                                                url1="Pentacad"
                                                text1="CASE STUDY"

                                                title2="CYBERSECURITY MANAGED SERVICE"
                                                image2="/images/current/featured.png"
                                                type2="Current" 
                                                url2="Cyber"
                                                text2="ONGOING PROJECT"
                                                 
                                                 />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}
export default Xcorp;