import React from 'react'
import {
  BrowserRouter as Router, 
  Routes,
  Route
} from 'react-router-dom'
import './App.scss'
import ScrollToTop from './components/util/ScrollToTop'
import Home from './components/pages/Home'
import About from './components/pages/About'
import Contact from './components/pages/Contact'
import Portfolio from './components/pages/Portfolio'
import ThisBrand from './components/projects/ThisBrand'
import Dalig from './components/projects/Dalig'
import Pegatanke from './components/projects/Pegatanke'
import PentaCad from './components/projects/PentaCad'
import Pentalogix from './components/projects/Pentalogix'
import Epico from './components/projects/Epico'
import Mokalu from './components/projects/Mokalu'
import Emergency from './components/projects/Emergency'
import ThisSite from './components/projects/ThisSite'
import DROTINI from './components/projects/DROTINI'
import Thestralcorp from './components/projects/Thestralcorp'
import Xcorp from './components/challenge/xcorp'
import Assessmment from './components/challenge/assessment'
import Cyber from './components/current/cyber'


function App() {
  return (
    <div>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path={'/About'} element={<About/>}></Route>
            <Route path={'/Contact'} element={<Contact/>}></Route>
            <Route path={'/Portfolio'} element={<Portfolio/>}></Route>
            <Route path={'/Portfolio/ThisBrand'} element={<ThisBrand/>}></Route>
            <Route path={'/Portfolio/Dalig'} element={<Dalig/>}></Route>
            <Route path={'/Portfolio/Pegatanke'} element={<Pegatanke/>}></Route>
            <Route path={'/Portfolio/PentaCad'} element={<PentaCad/>}></Route>
            <Route path={'/Portfolio/Pentalogix'} element={<Pentalogix/>}></Route>
            <Route path={'/Portfolio/Epico'} element={<Epico/>}></Route>
            <Route path={'/Portfolio/Mokalu'} element={<Mokalu/>}></Route>
            <Route path={'/Portfolio/Emergency'} element={<Emergency/>}></Route>
            <Route path={'/Portfolio/ThisSite'} element={<ThisSite/>}></Route>
            <Route path={'/Portfolio/DROTINI'} element={<DROTINI/>}></Route>
            <Route path={'/Portfolio/Thestralcorp'} element={<Thestralcorp/>}></Route>
            <Route path={'/challenge/Xcorp'} element={<Xcorp/>}></Route>
            <Route path={'/challenge/Assessment'} element={<Assessmment/>}></Route>
            <Route path={'/Current/Cyber'} element={<Cyber/>}></Route>
            
            <Route path={'/'} element={<Home/>}></Route>
          </Routes>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
